import React from 'react';
import styled from '@emotion/styled';
import { device } from './layout/GlobalStyles';
import painRelease from '../pain-release.pdf';
import treatment from '../treatment-contract.pdf';
import agreement from '../agreement.pdf';

const StyledFormLinks = styled.div`
    > p {
        font-weight: 700;
        margin-bottom: 54px;
        font-size: 18px;
        line-height: 161.5%;
    }
    a {
        border: 1.5px solid #c0cbcf;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        width: 320px;
        text-align: center;
        height: 140px;
        margin: 16px 0;
        align-items: center;
        cursor: pointer;
        transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    }

    div:hover {
        background: rgba(112, 201, 251, 0.2);
    }
    @media ${device.tablet} {
        a {
            width: 180px;
        }
        section {
            display: flex;
            justify-content: space-between;
        }
    }
    @media ${device.laptop} {
        a {
            width: 32%;
        }
        section {
            display: flex;
            justify-content: space-between;
        }
    }
`;

export const FormLinks: React.FC = () => {
    return (
        <StyledFormLinks className="small-container">
            <p>Please fill out these forms before we meet:</p>
            <section>
                <a download href={painRelease}>
                    <div>
                        <p>Medical Records</p>
                    </div>
                </a>
                <a download href={treatment}>
                    <div>
                        <p>Treatment Contract</p>
                    </div>
                </a>
                <a download href={agreement}>
                    <div>
                        <p>Agreement as to Resolution of Concerns</p>
                    </div>
                </a>
            </section>
        </StyledFormLinks>
    );
};
