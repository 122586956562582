import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Hero } from '../../components/Hero';
import { StaticImage } from 'gatsby-plugin-image';
import { FormLinks } from '../../components/Form-Links';
import { Seo } from '../../components/seo';

const breadCrumbNames = ['About Us', 'Intake Forms'];
const breadCrumbUrls = ['/about-us/', '/about-us/intake-forms'];

const IntakeForms: Page = () => {
    return (
        <>
            <Seo
                title="Intake Forms in Lafayette, Indiana - Innovative Medicine"
                description="Here you can find all of the appropriate forms you need to fill out prior to your visit."
            />
            <Hero
                title="Intake Forms"
                breadCrumbUrls={breadCrumbUrls}
                breadCrumbNames={breadCrumbNames}
            >
                <StaticImage
                    quality={100}
                    placeholder="none"
                    src="../../images/intake-forms.jpeg"
                    alt="intake forms"
                />
            </Hero>
            <FormLinks />
        </>
    );
};
IntakeForms.Layout = DefaultLayout;

export default IntakeForms;
